<template>
  <table class="recruit-table">
    <tr class="recruit-table__tr">
      <td class="recruit-table__tr__td--left">商号</td>
      <td class="recruit-table__tr__td--right">シンエイ車輌有限会社</td>
    </tr>
    <tr class="recruit-table__tr">
      <td class="recruit-table__tr__td--left">よみがな</td>
      <td class="recruit-table__tr__td--right">しんえいしゃりょう</td>
    </tr>
    <tr class="recruit-table__tr">
      <td class="recruit-table__tr__td--left">所在地</td>
      <td class="recruit-table__tr__td--right">大分県大分市大字大在2番地</td>
    </tr>
    <tr class="recruit-table__tr">
      <td class="recruit-table__tr__td--left">電話番号</td>
      <td class="recruit-table__tr__td--right">097-547-7723</td>
    </tr>
    <tr class="recruit-table__tr">
      <td class="recruit-table__tr__td--left">職種</td>
      <td class="recruit-table__tr__td--right">自動車整備工</td>
    </tr>
    <tr class="recruit-table__tr">
      <td class="recruit-table__tr__td--left">雇用形態</td>
      <td class="recruit-table__tr__td--right">正社員</td>
    </tr>
    <tr class="recruit-table__tr">
      <td class="recruit-table__tr__td--left">就業場所</td>
      <td class="recruit-table__tr__td--right">事業所所在地と同じ</td>
    </tr>
    <tr class="recruit-table__tr">
      <td class="recruit-table__tr__td--left">年齢</td>
      <td class="recruit-table__tr__td--right">年齢制限 あり（65歳以下）</td>
    </tr>
    <tr class="recruit-table__tr">
      <td class="recruit-table__tr__td--left">学歴</td>
      <td class="recruit-table__tr__td--right">不問</td>
    </tr>
    <tr class="recruit-table__tr">
      <td class="recruit-table__tr__td--left">必要な免許・資格</td>
      <td class="recruit-table__tr__td--right">
        二級自動車整備士<br>
        三級自動車整備士<br>
        ※ 上記、あれば尚可<br><br>
        普通自動車運転免許  必須
      </td>
    </tr>
    <tr class="recruit-table__tr">
      <td class="recruit-table__tr__td--left">試用期間</td>
      <td class="recruit-table__tr__td--right">試用期間あり  期間３ヶ月</td>
    </tr>
    <tr class="recruit-table__tr">
      <td class="recruit-table__tr__td--left">賃金</td>
      <td class="recruit-table__tr__td--right">
        月額（a+b）<br>
        165,000円 〜 260,000円<br><br>
        住宅（一律）手当  10,000円<br>
        大型手当 / 10,000円〜<br>
        整備手当 / 10,000円〜20,000円<br>
        家族手当 / 10,000円<br>
        <span>第1子 5,000円</span><br>
        <span>第2子 3,000円</span><br>
        特別・牽引・整備検査員・中型手当 / 各 5,000円<br>
        高所作業車検査員 / 3,000円<br>
        クレーン検査員 / 2,000円<br><br>
        通勤手当  一定額 月額 10,000円<br><br>
        賃金締切日  固定 毎月25日<br>
        賃金支払日  固定 翌月  5日<br><br>
        昇給  あり<br>
        金額  1月あたり 2,000円〜3,000円（前年度実績）<br>
        賞与  あり  年2回（前年度実績）<br>
        賞与月数  計 3.50月分（前年度実績）
      </td>
    </tr>
    <tr class="recruit-table__tr">
      <td class="recruit-table__tr__td--left">就業時間</td>
      <td class="recruit-table__tr__td--right">
        8時15分 〜 17時30分<br><br>
        時間外労働あり  月平均 20時間<br>
        休憩時間 30分 / 年間休日数 100日<br><br>
        6ヶ月経過後の年次有給休暇日数 10日
      </td>
    </tr>
    <tr class="recruit-table__tr">
      <td class="recruit-table__tr__td--left">休日</td>
      <td class="recruit-table__tr__td--right">
        日曜日，祝日，その他<br>
        週休2日制
      </td>
    </tr>
    <tr class="recruit-table__tr">
      <td class="recruit-table__tr__td--left">加入保険</td>
      <td class="recruit-table__tr__td--right">
        雇用保険，労災保険<br>
        健康保険，厚生年金
      </td>
    </tr>
    <tr class="recruit-table__tr">
      <td class="recruit-table__tr__td--left">退職金共済</td>
      <td class="recruit-table__tr__td--right">加入</td>
    </tr>
    <tr class="recruit-table__tr">
      <td class="recruit-table__tr__td--left">退職金制度</td>
      <td class="recruit-table__tr__td--right">あり（勤続年数4年以上）</td>
    </tr>
    <tr class="recruit-table__tr">
      <td class="recruit-table__tr__td--left">定年制</td>
      <td class="recruit-table__tr__td--right">あり（定年年齢一律65歳）</td>
    </tr>
    <tr class="recruit-table__tr">
      <td class="recruit-table__tr__td--left">再雇用制度</td>
      <td class="recruit-table__tr__td--right">あり</td>
    </tr>
    <tr class="recruit-table__tr">
      <td class="recruit-table__tr__td--left">勤務延長</td>
      <td class="recruit-table__tr__td--right">あり</td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.recruit-table {
  width: calc(100vw - 152px * 2);
  min-width: 60%;
  max-width: 78%;
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid $blue_color;
  &__tr {
    font-size: 1.8rem;
    vertical-align: top;
    &__td {
      padding: 26px 40px;
      border: 1px solid $blue_color;
      &--left {
        @extend .recruit-table__tr__td;
        width: 378px;
      }
      &--right {
        @extend .recruit-table__tr__td;
      }
      &__table {
        width: 100%;
        &__tr {
          width: 100%;
          &__td {
            &--left {
              width: 100px;
            }
            &--right {
              width: 75px;
            }
          }
        }
      }
    }
  }
}
</style>
