<template>
  <div :class="isSP ? 'recruit--sp' : 'recruit--pc'">
    <!-- トップビジュアル -->
    <sp-visual v-if="isSP" class="recruit__visual--sp" :img="require('@/assets/img/visual/recruit_sp.jpeg')" title="リクルート" />
    <pc-visual v-else class="recruit__visual--pc" :img="require('@/assets/img/visual/recruit_pc.jpeg')" title="リクルート" />
    <!-- 詳細情報 -->
    <sp-description v-if="isSP" class="recruit__description--sp" :main="description.main" :sub="description.sub" />
    <pc-description v-else class="recruit__description--pc" :main="description.main" :sub="description.sub" />
    <!-- 表 -->
    <h2 :class="isSP ? 'recruit__title--sp' : 'recruit__title--pc'">募集要項</h2>
    <sp-table v-if="isSP" class="recruit__table--sp" />
    <pc-table v-else class="recruit__table--pc" />
    <!-- 問い合わせ -->
    <sp-contact v-if="isSP" :isRecruit="true"  />
    <pc-contact v-else :isRecruit="true"  />
    <!-- ボトムイメージ -->
    <div v-if="isSP" class="recruit__images--sp">
      <v-img class="recruit__images__item--sp" :src="require('@/assets/img/recruit/image1.jpeg')" />
    </div>
    <div v-else class="recruit__images--pc">
      <v-img class="recruit__images__item--pc" :src="require('@/assets/img/recruit/image1.jpeg')" />
    </div>
  </div>
</template>

<script>
import SpVisual from '@/components/common/visual/sp'
import PcVisual from '@/components/common/visual/pc'
import SpDescription from '@/components/common/description/sp'
import PcDescription from '@/components/common/description/pc'
import SpTable from '@/components/recruit/table/sp'
import PcTable from '@/components/recruit/table/pc'
import SpContact from '@/components/common/contact/sp'
import PcContact from '@/components/common/contact/pc'

export default {
  name: 'recruit',
  components: { SpVisual, PcVisual, SpDescription, PcDescription, SpTable, PcTable, SpContact, PcContact },
  mounted () {
  },
  computed: {
    /**
     * @return {Boolean} SPデバイスかどうか
     */
    isSP () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    },
    /**
     * @return {Object} 説明文
     */
    description () {
      return {
        main: '自動車の民間車検場で車検・点検・整備・修理全般を行っていただきます。\n自動車の種類としては、トラック・特殊車両・吸引車・クレーン車・高所作業車等ですが、主にトラックの等の大型車がメインとなります。\n\n※ 経験者優遇\n※ 単身用・世帯用入居可能住宅有り\n※ 県外の方も大歓迎',
        sub: ''
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.recruit {
  width: 100%;
  &--sp {
    @extend .recruit;
    margin-top: $sp_header_height;
  }
  &--pc {
    @extend .recruit;
    margin-top: $pc_header_height;
  }
  &__title {
    text-align: center;
    &--pc {
      @extend .recruit__title;
      margin: 130px 0 0;
      font-size: 2.5rem;
      font-weight: 800;
    }
    &--sp {
      @extend .recruit__title;
      margin: 40px 0 0;
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
  &__description {
    &--sp {
      @extend .recruit__description;
      margin-top: 55px;
    }
    &--pc {
      @extend .recruit__description;
      margin-top: 141px;
    }
  }
  &__table {
    &--sp {
      @extend .recruit__table;
      margin: 24px auto 0 auto;
    }
    &--pc {
      @extend .recruit__table;
      margin: 68px auto 0 auto;
    }
  }
  &__images {
    &--sp {
      @extend .recruit__images;
      width: 100vw;
      margin-top: 100px;
    }
    &--pc {
      @extend .recruit__images;
      width: 100vw;
      margin-top: 230px;
    }
    &__item {
      width: 100%;
      &--sp {
        @extend .recruit__images__item;
        height: 237px;
      }
      &--pc {
        @extend .recruit__images__item;
        height: 693px;
      }
    }
  }
}
</style>
